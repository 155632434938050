import { useState } from "react"
import SectionHeading from "../../../componets/ui/SectionHeading"

const games = [
    {
        icon: "/img/beer-pong.webp",
        name:"Bear Pong",
        image: "/img/home.jpg"
    },
    {
        icon: "/img/beer-pong.webp",
        name:"Bear Pong",
        image: "/img/home.jpg"
    },
    {
        icon: "/img/beer-pong.webp",
        name:"Bear Pong",
        image: "/img/home.jpg"
    },
]

function DrinkingGames(){

    const [openModal, setOpenModal] = useState(0)

    const toggleState = (id)=>{
        if(id === 0){
            document.body.classList.remove("no-scroll")
            setOpenModal(0)
        }
        else{
            document.body.classList.add("no-scroll")
            setOpenModal(id)
        }
    }
    return (
        <section>
            <div className="drinking-game-heading">
                <h2></h2>
            </div>
            <div className="container">
                <div className="games-grid">
                    {/* {
                        games.map((game,index)=>(
                            <div className="game" key={index}>
                                <img className="game-image" src={game.image} alt="" />
                                <div className="game-content">
                                    <h2>Beer Pong</h2>
                                    <img src={game.icon} alt="" />
                                    <button className="btn read-more">Read More</button>
                                </div>
                            </div>
                        ))
                    } */}
                    <div className="game">
                        <img className="game-image" src="/img/game1.jpg" alt="people playing beer pong" />
                        <div className="game-content">
                            <h2>Beer Pong</h2>
                            <img src="/img/beer-pong.webp" alt="beer pong icon" />
                            {/* <button onClick={()=>toggleState(1)} className="btn read-more">Read More</button> */}
                        </div>
                        <div className={`game-pop-up-modal ${openModal === 1 && 'open'}`}>
                            <div onClick={()=>toggleState(0)} className="overlay"></div>
                            <div className="container">
                                <div className="modal-container">
                                    <div className="modal-header">
                                        <h4>King's Cup - Rules and information</h4>
                                    </div>
                                    <div className="modal-content">
                                        <p>King’s Cup is a great option because the rules are so simple. King’s Cup involves the drinks in your hand (typically beer) and a deck of cards. Each card is associated with an action or mini game that leads to some fun competition.</p>
                                    </div>
                                    <div className="modal-footer">
                                        <button onClick={()=>toggleState(0)} className="btn close-modal">Close</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="game">
                        <img className="game-image" src="/img/game2.jpg" alt="people playing drinking jenga" />
                        <div className="game-content">
                            <h2>Drinking Jenga</h2>
                            <img src="/img/drinking-jenga.webp" alt="drinking jenga icon" />
                            {/* <button onClick={()=>toggleState(2)} className="btn read-more">Read More</button> */}
                        </div>
                        <div className={`game-pop-up-modal ${openModal === 2 && 'open'}`}>
                            <div onClick={()=>toggleState(0)} className="overlay"></div>
                            <div className="container">
                                <div className="modal-container">
                                    <div className="modal-header">
                                        <h4>King's Cup - Rules and information</h4>
                                    </div>
                                    <div className="modal-content">
                                        <p>King’s Cup is a great option because the rules are so simple. King’s Cup involves the drinks in your hand (typically beer) and a deck of cards. Each card is associated with an action or mini game that leads to some fun competition.</p>
                                    </div>
                                    <div className="modal-footer">
                                        <button onClick={()=>toggleState(0)} className="btn close-modal">Close</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default DrinkingGames