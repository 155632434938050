import { Link, Outlet, useLocation } from 'react-router-dom';
import './Entertainment.css'
import { Helmet } from 'react-helmet-async';

function Entertainment() {
    const location = useLocation();
    return(
        <><Helmet>
            <title>City Pub Crawl</title>
            <meta name="description" content="Our Enterment activities, Meet our Team and Party with city pub crawl in krakow and enjoy the best night clubs in old town" />
            <link rel="canonical" href="/entertainment" />
        </Helmet><main>
                <header className='page-header'>
                    <div className="content">
                        <h1>Entertainment</h1>
                    </div>
                </header>
                <section>
                    <div className="container">
                        <nav className="tab">
                            <Link className={`tab-link ${location.pathname === '/entertainment' || location.pathname === '/entertainment/drinking-games' ? 'active' : ''}`} to="/entertainment/drinking-games">Drinking Games</Link>
                            <Link className={`tab-link ${location.pathname === '/entertainment/karaoke' ? 'active' : ''}`} to="/entertainment/karaoke">Karaoke</Link>
                        </nav>
                    </div>
                    <Outlet />
                </section>
            </main></>
    )
}

export default Entertainment;