import { MdOutlineKeyboardControlKey } from 'react-icons/md';
import './Faqs.css'
import { useState } from 'react';

const faqs = [
    {
        id:0,
        question: "What is the meaning of life?",
        answer: "We Really Don't Know"
    },
    {
        id:1,
        question: "What is the meaning of life?",
        answer: "We Really Don't Know"
    }
]

function Faqs() {

    const [openQuestion, setOpenQuestion] = useState(null)

    const toggleState = (id)=>{
        if(id === openQuestion)
            setOpenQuestion(null)
        else
            setOpenQuestion(id)
    }
    return(
        <main>
            <header className='page-header'>
                <div className="content">
                    <h1>Frequently Asked Questions</h1>
                </div>
            </header>
            <section>
                <div className="container">
                    <div className="faqs">
                        {
                            faqs.map((faq,index)=>(
                                <div className={`faq ${openQuestion === faq.id && 'open'}`} key={index}>
                                    <button type='button' onClick={()=>toggleState(faq.id)} className="btn question">
                                        <span>{faq.question}</span>
                                        <MdOutlineKeyboardControlKey />
                                    </button>
                                    {
                                      openQuestion === faq.id && (
                                        <div className="answer">{faq.answer}</div>
                                         )
                                    }
                                </div>
                            ))
                        }
                    </div>
                </div>
            </section>
        </main>
    )
}

export default Faqs;