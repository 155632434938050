import './Contact.css';
import emailjs from "@emailjs/browser"
import { useState,useEffect } from "react";
import ReactGA from "react-ga"
import { Helmet } from 'react-helmet-async';


function Contact(){
    useEffect(() =>{
        ReactGA.pageview(window.location.pathname)
      }, [])
    const [names, setnames] = useState("");
    const [email, setemail] = useState("");
    const [subject, setsubject] = useState("");
    const [message, setmessage] = useState("");
    const [loading, setloading] = useState("Send Message")

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("hererere",e, process.env)

        setloading("Sending...")
        const serviceID = process.env.REACT_APP_PUBLIC_EMAILJS_SERVICE_ID
        const templateID = process.env.REACT_APP_PUBLIC_EMAILJS_TEMPLATE_ID
        const userID = process.env.REACT_APP_PUBLIC_EMAILJS_USER_ID

        emailjs.sendForm(serviceID, templateID, e.target, userID)
            .then((result) => {
                setloading("Message Sent !!!")
                setTimeout(function () { setloading("Send Message") }, 5000);
                setnames("")
                setemail("")
                setsubject("")
                setmessage("")
                 console.log(result.text)
            },
                (error) => {
                    setloading("Failed, Try again !!!")
                    setTimeout(function () { setloading("Send Message") }, 5000);
                    console.log(error.text);
                }
            );
    };
    return(
        <><Helmet>
            <title>City Pub Crawl</title>
            <meta name="description" content="Contact us" />
            <link rel="canonical" href="/contact" />
        </Helmet><main>
                <header className='page-header'>
                    <div className="content">
                        <h1>Contact Us</h1>
                    </div>
                </header>
                <section>
                    <div className="container">
                        <div className="contact-wrapper">
                            <div className="contact-info">
                                <div className="info">
                                    <div className="info-title">Krakow Contacts</div>
                                    <div className="info-group">
                                        <span>Contact: <b>Pablo</b></span>
                                        <a href="tel:+48538213560">+48538213560</a>
                                    </div>
                                    <div className="info-group">
                                        <span>Email</span>
                                        <p>info@citypubcrawl.pl</p>
                                    </div>
                                    {/* <div className="info-group">
                                        <span>Location</span>
                                        <p>Poland,Krakow</p>
                                    </div> */}
                                </div>
                                <div className="info">
                                   <div className="info-title">Katowice Contacts</div>
                                   <div className="info-group">
                                        <span>Contact: <b>Pablo</b></span>
                                        <a href="tel:+48538213560">+48538213560</a>
                                    </div>                                   
                                    <div className="info-group">
                                        <span>Email</span>
                                        <p>info@citypubcrawl.pl</p>
                                    </div>
                                </div>
                            </div>
                            <form className="contact-form" onSubmit={handleSubmit} method='post'>
                                <div className="form-group">
                                    <label htmlFor="email">Email address</label>
                                    <input required={true} id={'Email'} type={'email'} name={'email'} placeholder={'Your email'} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="firstname">Name</label>
                                    <input required={true} id={"name"} type={"text"} name={"name"} placeholder={"Your names"} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="subject">Subject</label>
                                    <input required={true} id={"name"} type={"text"} name={"subject"} placeholder={"Subject"} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="message">Message</label>
                                    <textarea id={'message'} name={'message'} placeholder={'Write your message here...'}></textarea>
                                </div>
                                <button type='submit' className="btn submit">{loading}</button>
                            </form>
                        </div>
                    </div>
                </section>
            </main>
            </>
    )
}

export default Contact;
