import './About.css'
import { Helmet } from 'react-helmet-async';

const members = [
  {
    name: "Pablo",
    info: "Hey, Am that Pablo that everyone is talking about, I work hard and party hard",
    image: "img/pablo.jpg"
  },
  {
    name: "Enio",
    info: "Hey, I like to dance and meet new people, let's party!",
    image: "img/enio.jpg"
  },
  {
    name: "Tim",
    info: "Timmy boi challenge every week jäger every night",
    image: "img/timmy.jpg"
  },
  {
    name: "Elia",
    info: "Am Elia and I like to party",
    image: "img/elia.jpg"
  },
  {
    name: "Jone",
    info: "Hey Am from Finland I skate board am outgoing guy and I like party with tourists",
    image: "img/jone.jpg"
  },

  ];
  
function About(){
    return(
        <><Helmet>
            <title>City Pub Crawl</title>
            <meta name="description" content="Meet our Team and Party with city pub crawl in krakow and enjoy the best night clubs in old town" />
            <link rel="canonical" href="/about" />
        </Helmet><main>
                <header className='page-header'>
                    <div className="content">
                        <h1>About Us</h1>
                        <h4>Meet the team !</h4>
                        <p>Join the new exciting Pub Crawl with experienced guides that will take you to the best clubs and party with them.</p>
                    </div>
                </header>
                <section>
                    <div className="container">
                        <div className="team">
                            {members.map((member, index) => (
                                <div className="member" key={index}>
                                    <img className='member-image' src={member.image} alt={member.name} />
                                    <div className="member-details">
                                        <div className="wrapper">
                                            <h3 className="name">{member.name}</h3>
                                            <p className='info'>{member.info}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
            </main></>
    )
}

export default About;
