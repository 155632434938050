import './MeetingPoint.css';
import SectionHeading from "../../componets/ui/SectionHeading"
import ReactGA from "react-ga"
import { useEffect } from "react";
import { Helmet } from 'react-helmet-async';

function MeetingPoint(){
    useEffect(() =>{
        ReactGA.pageview(window.location.pathname)
      }, [])
    return (
        <>
        <Helmet>
            <title>City Pub Crawl</title>
            <meta name="description" content="Pub Crawl Meeting point" />
            <link rel="canonical" href="/entertainment" />
        </Helmet>
            <main>
                <section className="meeting-points">
                    <div>
                        <header className='page-header'>
                            <div className="content">
                                <h1>Krakow Meeting Point</h1>
                            </div>
                        </header>
                        <div>
                            <div className="container">
                                <div className="meeting-point-heading">
                                    <p>We meet every night at 9pm (GMT+2) by the Eros Bendato head</p>
                                    <a href="https://maps.app.goo.gl/tPtiopgSPKB6DDCJ6" target="_blank" rel="noreferrer">Address : 31-010, Rynek Główny 29-30, 33-332 Kraków, Poland</a>
                                    <img src="/img/krakow-location.jpg" alt="" />
                                </div>
                            </div>
                        </div>
                        <div>
                            <SectionHeading heading={"Just here.."} />
                            <div className="map">
                                <iframe title="meeting point krakow" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10245.283671762443!2d19.9361991!3d50.0615513!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47165b0ddfd3b671%3A0xef664ee30ec69546!2sEros%20Bendato!5e0!3m2!1sen!2szm!4v1711747726149!5m2!1sen!2szm" width="600" height="450" style={{ border: 0 }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                        </div>
                    </div>
                    <div>
                        <header className='page-header'>
                            <div className="content">
                                <h1>Katowice Meeting Point</h1>
                            </div>
                        </header>
                        <div>
                            <div className="container">
                                <div className="meeting-point-heading">
                                    <p>We meet every night at 9pm (GMT+2) by Pomnik Harcerzy Września</p>
                                    <a href="https://maps.app.goo.gl/v5fyoShyXsPjygFd6" target="_blank" rel="noreferrer">Address : Plac Obrońców, Katowice, Poland</a>
                                    <img src="/img/katowice-location.jpg" alt="" />
                                </div>
                            </div>
                        </div>
                        <div>
                            <SectionHeading heading={"Just here.."} />
                            <div className="map">
                               <iframe title='meeting point katowice' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2550.6707535345663!2d19.019049975514072!3d50.26073330100714!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4716ce37814e8f8f%3A0x3672802534c23c9e!2sPomnik%20Harcerzy%20Wrze%C5%9Bnia!5e0!3m2!1sen!2szm!4v1718482507391!5m2!1sen!2szm" width="600" height="450" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            </>
    )
}

export default MeetingPoint