import { Link } from 'react-router-dom';
import './Footer.css';

function Footer(){
    return(
        <footer>
            <div className='footer-container'>
                <p>© {new Date().getFullYear()} City Pub Crawl</p>
                <ul>
                    <li><Link to="/terms-and-conditions">Terms and Conditions</Link></li>
                    <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                </ul>
            </div>
        </footer>
    )
}


export default Footer;