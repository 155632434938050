import './Blog.css'
import { Helmet } from 'react-helmet-async';

const blogs = [
    {
      title: "Exploring the Power of AI in Web Design",
      description: "Artificial intelligence is rapidly transforming various industries, and web design is no exception. This blog post dives into the exciting possibilities of AI for creating personalized, dynamic, and user-friendly websites.",
      image: "/img/blog-placeholder.jpg",
      author: {
        name: "Emily Jones",
        image: "/img/avatar-placeholder.jpg",
      },
      date: "2024-03-24",
    },
    {
      title: "Top 5 Tips for Effective Content Marketing",
      description: "Content marketing is a powerful tool for attracting and engaging your target audience. This blog post outlines five essential tips to help you create valuable, informative, and SEO-friendly content that gets results.",
      image: "/img/blog-placeholder.jpg",
      author: {
        name: "David Lee",
        image: "/img/avatar-placeholder.jpg",
      },
      date: "2024-03-20",
    },
    {
      title: "The Future of E-commerce: Trends to Watch",
      description: "The e-commerce landscape is constantly evolving. This blog post explores some of the key trends shaping the future of online shopping, including personalization, voice commerce, and augmented reality.",
      image: "/img/blog-placeholder.jpg",
      author: {
        name: "Michael Brown",
        image: "/img/avatar-placeholder.jpg",
      },
      date: "2024-03-18",
    },
    {
      title: "Mastering the Art of Social Media Engagement",
      description: "Social media is a powerful tool for connecting with your audience and building brand awareness. This blog post provides actionable tips to help you create engaging social media content that sparks conversations and drives results.",
      image: "/img/blog-placeholder.jpg",
      author: {
        name: "Sarah Miller",
        image: "/img/avatar-placeholder.jpg",
      },
      date: "2024-03-15",
    },
    {
      title: "Unlocking the Potential of Data Analytics for Businesses",
      description: "Data is a valuable asset for any business. This blog post explores how data analytics can be used to gain insights into customer behavior, optimize marketing campaigns, and make informed business decisions.",
      image: "/img/blog-placeholder.jpg",
      author: {
        name: "John Doe",
        image: "/img/avatar-placeholder.jpg",
      },
      date: "2024-03-12",
    },
    {
      title: "Essential Web Design Principles for Beginners",
      description: "Creating a user-friendly and visually appealing website requires an understanding of fundamental design principles. This blog post provides a beginner-friendly guide to essential web design principles that will help you create effective websites.",
      image: "/img/blog-placeholder.jpg",
      author: {
        name: "Lisa Garcia",
        image: "/img/avatar-placeholder.jpg",
      },
      date: "2024-03-10",
    },
  ];
  
function Blog() {

  function truncateString(str, maxLength) {
    if (str.length <= maxLength) {
      return str;
    } else {
      return str.substring(0, maxLength) + "...";
    }
  }

    function convertDateToMonthsAndDaysAgo(dateStr) {
        const pastDate = new Date(dateStr);
        const today = new Date();
      
        if (isNaN(pastDate.getTime())) {
          return "Invalid date";
        }
      
        if (pastDate > today) {
          return "Future date";
        }
      
        const yearsDiff = today.getFullYear() - pastDate.getFullYear();
        const monthsDiff = (today.getMonth() - pastDate.getMonth()) + yearsDiff * 12;
      
        // Calculate the difference in days, considering months with varying lengths
        const daysInMonth = 32 - new Date(pastDate.getFullYear(), pastDate.getMonth(), 32).getDate();
        const daysDiff = (
          today.getDate() - pastDate.getDate() +
          (today.getMonth() - pastDate.getMonth()) * daysInMonth +
          yearsDiff * 365
        );
      
        // Create a combined string representing months and days
        const resultStr = `${monthsDiff} ${monthsDiff === 1 ? "month" : "months"} ${daysDiff > 0 ? `and ${daysDiff} days` : ""} ago`;
      
        return monthsDiff === 0 ? "Today" : resultStr;
      }
      
      
    return(
      <><Helmet>
        <title>City Pub Crawl</title>
        <meta name="description" content="Meet our Team and Party with city pub crawl in krakow and enjoy the best night clubs in old town" />
        <link rel="canonical" href="/blog" />
      </Helmet><main>
          <header className='page-header'>
            <div className="content">
              <h1>View Our Blog</h1>
              <h4>Follow the party</h4>
            </div>
          </header>
          <section>
            <div className="container">
              <div className="blogs">
                {blogs.map((blog, index) => (
                  <div className="post" key={index}>
                    <div className="post-image">
                      <img src="/img/home.jpg" alt={blog.title} />
                    </div>
                    <div className="post-details">
                      <div className="author-image">
                        <img src={blog.author.image} alt={blog.author.name} />
                      </div>
                      <h5 className='author-name'>{blog.author.name}</h5>
                      <h3 className="post-title">{truncateString(blog.title, 30)}</h3>
                      <span className="post-date">{convertDateToMonthsAndDaysAgo(blog.date)}</span>
                      <p className="post-description">{truncateString(blog.description, 145)}</p>
                      <button className='btn read-more'>Read More</button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>
        </main></>
    )
}

export default Blog;