
function Karaoke(){
    return (
        <section>
            <div className="container">
                <div className="karaoke">
                    <div className="karaoke-image">
                        {/* <img src="/img/karaoke.jpg" alt="" /> */}
                    </div>
                    <div className="karaoke-heading">
                        <h2>Karaoke Bar</h2>
                    </div>
                    <div className="karaoke-content">
                        <p>We take the group every night for karaoke</p>
                        {/* <p>Are you ready to unleash your inner rockstar or simply have a blast singing your heart out with friends? Look no further than Let's Sing Karaoke Bar, our esteemed partners for all things karaoke in the heart of the vibrant city of Krakow, Poland. Let's Sing Karaoke Bar is your go-to destination for a night filled with music, fun, and unforgettable memories. Whether you're a solo sensation or looking to harmonize with friends, Let's Sing Karaoke Bar is the place to be. So, grab your friends, pick your favorite songs, and join Let's Sing Karaoke Bar for a karaoke experience like no other in Krakow.</p>
                        <h5>Operating Hours</h5>
                        <p>Let's Sing Karaoke Bar is here to make sure your karaoke cravings are met whenever they strike. They're open every day from 7 pm to 3 am, giving you plenty of time to pick your favorite songs and hit the stage.</p> */}
                    </div>
                    <div className="featured-images">
                        {/* <img src="/img/featured1.jpg" alt="" />
                        <img src="/img/featured2.jpg" alt="" /> */}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Karaoke