import './Nav.css';
import { MdHome, MdLocalBar, MdPhone, MdMenu, MdPeopleAlt, MdMap, MdClose } from "react-icons/md";
import { SiFacebook, SiInstagram, SiTripadvisor } from "react-icons/si";
import { Link } from 'react-router-dom';
import { useWidget } from '../../WidgetContext';
import { useEffect, useState } from 'react';

function Nav() {
  const [openMenu, setOpenMenu] = useState(false);
  const { widgetOpen,setWidgetOpen, loadWidget} = useWidget();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (openMenu && !event.target.closest('.site-nav')) {
        setOpenMenu(false);
      }
    };
    const listener = document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', listener);
  }, [openMenu]);

  return (
    <div>
      <nav className='site-nav'>
        <div className="container">
          <div className="navbar">
            <Link to="/" className="brand">
              <img src="/logo.svg" alt="City Pub Crawl" />
            </Link>
            <button className="btn book-btn checkoutButton" onClick={loadWidget}>Book Now</button>
            <button className="nav-toggler" onClick={() => setOpenMenu(!openMenu)} type="button" aria-controls="nav" aria-expanded={openMenu ? "true" : "false"} aria-label="Toggle navigation">
              {openMenu ? <MdClose /> : <MdMenu />}
            </button>
            <div className={`navbar-nav ${openMenu ? "collapse open" : "collapse"}`}>
              <ul className="nav-list">
                <li className="nav-item">
                  <Link className="nav-link" onClick={() => setOpenMenu(!openMenu)} to="/">
                    <MdHome /> Home
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" onClick={() => setOpenMenu(!openMenu)} to="/about">
                    <MdPeopleAlt />
                    About Us
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" onClick={() => setOpenMenu(!openMenu)} to="/meeting-point">
                    <MdMap />
                    Meeting Point
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" onClick={() => setOpenMenu(!openMenu)} to="/entertainment">
                    <MdLocalBar /> Entertainment
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" onClick={() => setOpenMenu(!openMenu)} to="/contact">
                    <MdPhone /> Contact
                  </Link>
                </li>
                <li className="nav-item">
                  <a className="nav-link" onClick={() => setOpenMenu(!openMenu)} href="https://www.facebook.com/profile.php?id=61558022615536" target='_blank' rel="noreferrer">
                    <SiFacebook />
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" onClick={() => setOpenMenu(!openMenu)} href="https://www.instagram.com/city.pubcrawl?igsh=enViYTV6N2p3NHh2" target='_blank' rel="noreferrer">
                    <SiInstagram />
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" onClick={() => setOpenMenu(!openMenu)} href="https://www.tripadvisor.co.uk/Attraction_Review-g274772-d27599297-Reviews-City_Pub_Crawl_Krakow-Krakow_Lesser_Poland_Province_Southern_Poland.html" target='_blank' rel="noreferrer">
                    <SiTripadvisor />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
      <div className={`widget-container ${widgetOpen === true ? 'widget-open' : ''}`} >
          <div onClick={()=>setWidgetOpen(false)} className="widget-overlay"></div>
          <div className="widget-iframe">
            {/* <MdClose onClick={()=>setWidgetOpen(false)} className='close-icon'/> */}
            {/* Insert the TicketingHub iframe here */}
          </div>
      </div>
    </div>
  );
}

export default Nav;
