import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Home from './pages/home/Home';
import Footer from './componets/layout/Footer';
import Nav from './componets/layout/Nav';
import Contact from './pages/contact/Contact';
import About from './pages/about/About';
import Entertainment from './pages/entertainment/Entertainment';
import Blog from './pages/blog/Blog';
import Faqs from './pages/faqs/Faqs';
import MeetingPoint from './pages/meeting-point/MeetingPoint';
import Karaoke from './pages/entertainment/karaoke/Karoke';
import DrinkingGames from './pages/entertainment/drinking-games/DrinkingGames';
import { WidgetProvider } from './WidgetContext';
import PrivacyPolicy from './pages/legals/PrivacyPolicy';
import TermsAndConditions from './pages/legals/TermsAndConditions';
import WhatsappButton from './componets/ui/WhatsappButton';
import ReactGA from "react-ga"
ReactGA.initialize('G-CZRTSJ8X2J')
function App() {

  return (
    <WidgetProvider>
      <BrowserRouter>
            <Nav/>
              <Routes>
                <Route path='/' element={<Home />} />
                <Route path='/*' element={<Home />} />
                <Route path='/about' element={<About />} />
                <Route path='/meeting-point' element={<MeetingPoint />} />
                <Route path='/entertainment' element={<Entertainment />}>
                  <Route index element={<DrinkingGames/>}/>
                  <Route path="drinking-games" element={<DrinkingGames/>} />
                  <Route path="karaoke" element={<Karaoke/>} />
                </Route>
                <Route path='/blog' element={<Blog />} />
                <Route path='/contact' element={<Contact />} />
                <Route path='/faqs' element={<Faqs />} />
                <Route path='/privacy-policy' element={<PrivacyPolicy />} />
                <Route path='/terms-and-conditions' element={<TermsAndConditions />} />
              </Routes>
              <WhatsappButton/>
          <Footer/>
      </BrowserRouter>
    </WidgetProvider>
  );
}
export default App;
