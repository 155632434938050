import { createContext, useContext, useState } from "react";

const WidgetContext = createContext();

export const WidgetProvider = ({ children }) => {
    const [widgetOpen, setWidgetOpen] = useState(false);
    const [widgetLoaded, setWidgetLoaded] = useState(false);


    const loadWidget = () => {
        if (!widgetLoaded) {
          const script = document.createElement('script');
          script.src = 'https://assets.ticketinghub.com/checkout.js';
          script.setAttribute('data-widget', 'd70bb2ce-dd04-4752-b6ea-b3b5ea563052');
          const widgetContainer = document.querySelector('.widget-iframe');
          
          // Append the script to the container
          widgetContainer.appendChild(script);      
          setWidgetLoaded(true);
        }
        setWidgetOpen(true);
      };
  
    return (
      <WidgetContext.Provider value={{ widgetOpen, setWidgetOpen, loadWidget}}>
        {children}
      </WidgetContext.Provider>
    );
  };

  export const useWidget = () => {
    return useContext(WidgetContext);
  };